const LOCAL_STORAGE_KEY = "SCREENTJE-DEALTJE";

export function getLocalStorage() {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
}

export function setLocalStorage(state) {
  return localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
}

export function resetLocalStorage() {
  return localStorage.removeItem(LOCAL_STORAGE_KEY);
}
