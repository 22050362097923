import React from "react";

import "./footer.scss";
import { ReactComponent as LogoWieni } from "../../wieni.svg";

const Footer = () => (
  <header className="footer">
    <div className="footer__closure">
      <span className="">Een initiatief van</span>
      <img
        className="logo-medianest"
        src={`${process.env.PUBLIC_URL}/logo.png`}
        alt="Medianest"
      />
    </div>
    <a
      href="https://www.wieni.be/"
      target="_blank"
      rel="noopener noreferrer"
      className="logo-wieni"
    >
      <LogoWieni width="61" />
    </a>
  </header>
);

export default Footer;
