import React, { Suspense } from "react";
import { Router, View } from "react-navi";
import { mount, lazy, compose, withView } from "navi";

import Layout from "./components/layout/Layout";
import AnalyticsBoundary from "./components/analytics/AnalyticsBoundary";
import ErrorBoundary from "./components/error-boundary/ErrorBoundary";

import { useResults } from "./useResults";

const routes = compose(
  withView((request, context) => (
    // This is the first view -- it renders the second within a
    // `<Layout>` component.
    <Layout path={request.path} context={context}>
      <View />
    </Layout>
  )),
  mount({
    "/": lazy(() => import("./screens/common/main")),
    "/start": lazy(() => import("./screens/common/start")),
    "/vraag": lazy(() => import("./screens/common/question")),
    "/einde": lazy(() => import("./screens/common/end")),
    "/overzicht": lazy(() => import("./screens/v1/report")),
    "/v2/overzicht": lazy(() => import("./screens/v2/report"))
  })
);

const App = () => {
  const [
    users,
    results,
    activeUser,
    initUserResults,
    endUserResults,
    setResult,
    resetResults
  ] = useResults();

  return (
    <Router
      routes={routes}
      context={{
        users,
        results,
        activeUser,
        initUserResults,
        endUserResults,
        setResult,
        resetResults
      }}
    >
      <AnalyticsBoundary>
        <ErrorBoundary>
          <Suspense fallback={null}>
            <View />
          </Suspense>
        </ErrorBoundary>
      </AnalyticsBoundary>
    </Router>
  );
};
export default App;
