import React from "react";
import BusyIndicator from "react-busy-indicator";
import cx from "classnames";
import { NotFoundBoundary, useLoadingRoute, Link } from "react-navi";
import { useNavigation } from "react-navi";
import Reset from "../../components/reset/Reset";

import "./layout.scss";

import StatusBar from "../../components/status-bar/StatusBar";
import Footer from "../../components/footer/Footer";

export default function Layout({ path, context, children }) {
  // If there is a route that hasn't finished loading, it can be
  // retrieved with `useLoadingRoute()`.
  let loadingRoute = useLoadingRoute();
  let navigation = useNavigation();

  const handleReset = () => {
    context.resetResults();
    navigation.navigate("/");
  };

  const homepage = path === "/";
  const showReset = path.includes("einde") || path.includes("overzicht");

  return (
    <div className={cx("layout", { "is-homepage": homepage })}>
      {/* This component shows a loading indicator after a delay */}
      <BusyIndicator isBusy={!!loadingRoute} delayMs={200} />
      {!homepage && <StatusBar name="Screentje Dealtje" />}
      <div className="layout__main">
        <NotFoundBoundary render={() => <NotFound />}>
          {children}
        </NotFoundBoundary>
      </div>
      {showReset && <Reset onReset={handleReset} />}
      <Footer />
    </div>
  );
}

function NotFound() {
  return (
    <div className="Layout-error">
      <h2>404 Not Found</h2>
      <p>Deze pagina konden we niet vinden.</p>
      <Link className="btn btn--primary" href={"/"} prefetch={null}>
        <span className="btn__text">Keer terug</span>
        <span className="btn__icon">
          <svg width="20" height="20">
            <use xlinkHref="#arrow-right" />
          </svg>
        </span>
      </Link>
    </div>
  );
}
