import React from "react";

import "./reset.scss";

const Reset = ({ onReset }) => {
  return (
    <div className="reset">
      <button className="reset-btn" onClick={onReset}>
        <svg className="reset-btn__icon" width="18" height="16">
          <use xlinkHref="#reset" />
        </svg>
        <span className="reset-btn__text">Opnieuw starten</span>
      </button>
    </div>
  );
};

export default Reset;
