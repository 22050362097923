const trackGAEvent = data => {
  if (!window.dataLayer) {
    return;
  }

  const { category = "", action = "", label = "", value = "" } = data;

  const event = {
    event: "GAEvent",
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    eventValue: value
  };

  if (process.env.NODE_ENV !== "production") {
    console.log("📊", event);
  }
  window.dataLayer.push(event);
};

const trackGAVirtualPageView = data => {
  if (!window.dataLayer) {
    return;
  }

  const event = {
    event: "GAVirtualPageView",
    ...data
  };

  if (process.env.NODE_ENV !== "production") {
    console.log("📊", event);
  }

  window.dataLayer.push(event);
};

export { trackGAEvent, trackGAVirtualPageView };
