import React from "react";

import "./status-bar.scss";

const StatusBar = ({ name }) => (
  <header className="status-bar">
    <span className="status-bar__title">{name}</span>
  </header>
);

export default StatusBar;
