import { useEffect } from "react";
import { useNavigation } from "react-navi";
import { trackGAVirtualPageView } from "../../helpers/analytics";

const AnalyticsBoundary = ({ children }) => {
  const navigation = useNavigation();

  useEffect(() => {
    navigation.subscribe(route => {
      if (route.type === "ready") {
        trackGAVirtualPageView({
          virtualPagePath: `afspraken${route.url.pathname}`,
          virtualPageTitle: route.title
        });
      }
    });
  }, [navigation]);

  return children;
};

export default AnalyticsBoundary;
