import { useReducer, useEffect } from "react";

import questions from "./data/questions.json";
import {
  getLocalStorage,
  setLocalStorage,
  resetLocalStorage
} from "./helpers/local-storage";

const INIT_USER_RESULTS = "INIT_USER_RESULTS";
const END_USER_RESULTS = "END_USER_RESULTS";
const RESET_RESULTS = "RESET_RESULTS";
const SET_RESULT = "SET_RESULT";

function reducer(state, action) {
  switch (action.type) {
    case INIT_USER_RESULTS: {
      return {
        nextUserId: state.nextUserId + 1,
        users: [
          ...state.users,
          {
            id: state.nextUserId,
            name: action.payload.name,
            avatar: action.payload.avatar,
            active: true
          }
        ],
        results: [
          ...state.results,
          {
            userId: state.nextUserId,
            answers: questions.map((q, i) => ({
              q: `${i + 1}`,
              a: null
            }))
          }
        ]
      };
    }
    case END_USER_RESULTS: {
      return {
        ...state,
        users: state.users.map(u => ({
          ...u,
          active: false
        }))
      };
    }
    case SET_RESULT: {
      return {
        ...state,
        results: state.results.map(result => {
          if (result.userId === action.payload.userId) {
            return {
              ...result,
              answers: result.answers.map(answer => {
                if (answer.q === action.payload.questionId) {
                  return {
                    ...answer,
                    a: action.payload.answer
                  };
                }
                return answer;
              })
            };
          }

          return result;
        })
      };
    }
    case RESET_RESULTS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
const initialState = {
  nextUserId: 0,
  users: [],
  results: []
};

export function useResults() {
  const [state, dispatch] = useReducer(reducer, init());

  function init() {
    return getLocalStorage() ? getLocalStorage() : initialState;
  }

  function initUserResults(name, avatar) {
    return dispatch({
      type: INIT_USER_RESULTS,
      payload: {
        name,
        avatar
      }
    });
  }

  function endUserResults() {
    return dispatch({
      type: END_USER_RESULTS
    });
  }

  function setResult(userId, questionId, answer) {
    return dispatch({
      type: SET_RESULT,
      payload: {
        userId: userId,
        questionId: questionId,
        answer
      }
    });
  }

  function resetResults() {
    resetLocalStorage();

    return dispatch({
      type: RESET_RESULTS
    });
  }

  const activeUser = state.users.filter(({ active }) => active)[0] || null;

  useEffect(() => {
    setLocalStorage(state);
  }, [state]);

  return [
    state.users,
    state.results,
    activeUser,
    initUserResults,
    endUserResults,
    setResult,
    resetResults
  ];
}
